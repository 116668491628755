.ctf-button {
  display: inline-block;
  vertical-align: top;
  color: $purple;
  border: 2px solid $purple;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: rem(14);
  line-height: rem(24);
  background-color: $white;
  padding-top: rem(8);
  padding-bottom: rem(5);
  padding-left: rem(34);
  padding-right: rem(34);
  letter-spacing: 1.2px;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  margin: rem(10) 0;
  outline: none !important;

  &:hover,
  &:focus {
    color: $white;
    background-color: $purple;
    text-decoration: none;
  }

  &-white {
    color: $black;
    background-color: $white;
    border: 2px solid $white;

    &:hover,
    &:focus {
      color: $black;
      background-color: $lighten-gray;
    }
  }

  &-purple {
    color: $white;
    background-color: $purple;
    font-weight: 700;

    &:hover,
    &:focus {
      background-color: $light-purple;
      color: $white;
    }

    &-bordered {
      border: 2px solid $purple;

      &:hover {
        border-color: $light-purple;
      }
    }
  }

  &-green {
    color: $white;
    background-color: $green;
    font-weight: 700;
    border: 2px solid $green;

    &:hover,
    &:focus {
      background-color: lighten($green, 5%);
      color: $white;
    }
  }

  &-large {
    text-align: center;
    letter-spacing: normal;
    padding-top: rem(36);
    padding-left: rem(49);
    padding-right: rem(49);
    padding-bottom: rem(23);

    @media screen and (max-width: $xs-max-width) {
      padding-left: rem(35);
      padding-right: rem(35);
    }

    .ctf-button-main-text {
      display: block;
      font-size: rem(34);
      font-weight: 700;
      margin-bottom: rem(7);
    }

    .ctf-button-sub-text {
      display: block;
      font-size: rem(16);
      font-weight: 300;
    }
  }

  &-clean {
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: rem(14);
    line-height: rem(24);
    color: $purple;

    &:hover,
    &:focus {
      color: $light-purple;
      background-color: transparent;
    }
  }

  &-dark-purple {
    color: $white;
    background-color: $purple-gray;

    &:hover,
    &:focus {
      background-color: darken($purple-gray, 6);
      color: $white;
    }
  }

  &-disabled {
    color: $white !important;
    opacity: 0.34;
    cursor: default;
  }

  &-bordered-gray {
    border: 2px solid rgba($purple, 0.2);

    &:hover {
      color: $purple;
      background-color: initial;
      border-color: $purple;
    }
  }
}
