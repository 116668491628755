.ctf-offer {
  margin-bottom: rem(80);

  &-wrapper {
    padding-top: rem(56);
    padding-bottom: rem(56);
    position: relative;
    overflow: hidden;
    border-top: 1px solid $lighten-gray-3;
    border-bottom: 1px solid $lighten-gray-3;
  }

  &-container {
    text-align: center;
    background-color: $lighten-gray-5;
    border-radius: 5px;
    padding-top: rem(45);
    padding-bottom: rem(43);
    padding-left: rem(45);
    padding-right: rem(45);

    @media screen and (max-width: $md-max-width) {
      padding-top: rem(35);
      padding-left: rem(35);
      padding-right: rem(35);
      padding-bottom: rem(33);
    }

    @media screen and (max-width: $sm-max-width) {
      padding-top: rem(25);
      padding-left: rem(25);
      padding-right: rem(25);
      padding-bottom: rem(25);
    }

    @media screen and (max-width: $xs-max-width) {
      padding-top: rem(25);
      padding-left: rem(15);
      padding-right: rem(15);
      padding-bottom: rem(25);
    }
  }

  &-title {
    h2 {
      margin-bottom: rem(15);
    }
  }

  &-sub-title {
    margin-bottom: rem(32);
    text-transform: uppercase;
    font-size: rem(18);
    line-height: rem(30);
    color: $purple-gray;
  }

  &-content {
    color: $purple-gray;
    font-size: rem(18);
    line-height: rem(30);

    b {
      font-weight: 700;
    }
  }

  &-button {
    margin-top: rem(32);
  }
}
