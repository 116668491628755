html {
  font-size: 14px;
  line-height: 24px;

  @media (max-width: $xl-max-width) {
    font-size: 13px;
    line-height: 23px;
  }

  @media (max-width: $sm-max-width) {
    font-size: 12px;
    line-height: 22px;
  }
}

body {
  position: relative;
  font-family: $base-font-family;
  color: $purple-gray;
  font-weight: 300;
  font-style: normal;
  overflow-x: hidden;
  font-size: 14px;
}

img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $purple;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

.w100p {
  width: 100%;
}

.container-fluid {
  max-width: $container-fluid-max-width;
  position: relative;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(0 0 0 0);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.tooltip {
  &-inner {
    padding: rem(7) rem(25);
    text-transform: uppercase;
    font-size: rem(15);
    background: $darken-purple;
  }
}
