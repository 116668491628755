.ctf-hire {
  margin-bottom: rem(120);

  &-img-content {
    @media (max-width: $sm-max-width) {
      order: -1;
    }
  }

  &-offer {
    margin-bottom: rem(50);

    @media (max-width: $sm-max-width) {
      margin-bottom: 0;
    }
  }

  &-offer-category {
    position: relative;
    color: $darken-purple;
    font-size: rem(36);
    font-weight: 900;
    margin-bottom: rem(70);
  }

  &-offer-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: rem(30);
  }

  &-offer-icon {
    flex-shrink: 0;
    fill: $purple-gray;
    width: rem(45);
    height: rem(45);
    margin-right: rem(10);

    @media (max-width: $md-max-width) {
      width: rem(40);
      height: rem(40);
      margin-right: rem(5);
    }
  }

  &-offer-item-title {
    color: $darken-purple;
    font-size: rem(24);
    font-weight: 700;
    font-style: italic;
    padding-top: rem(10);
    margin-bottom: rem(15);
  }

  &-offer-item-text {
    color: $purple-gray;
    font-size: rem(18);
    line-height: rem(24);
  }

  &-offer-img-container {
    padding-top: rem(50);
    padding-left: rem(10);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    @media (max-width: $md-max-width) {
      align-items: center;
    }

    @media (max-width: $sm-max-width) {
      align-items: flex-start;
      padding-top: 0;
      padding-left: 0;
      margin-bottom: rem(30);
    }
  }

  &-form {
    margin-bottom: rem(90);
  }

  &-form-container {
    background-color: $lighten-gray-5;
    padding: rem(110) rem(15);
    border-radius: 5px;
  }

  &-form-slogan {
    display: block;
    text-align: center;
    color: $darken-purple;
    font-size: rem(36);
    line-height: rem(45);
    font-weight: 900;
    margin-bottom: rem(35);

    @media screen and (max-width: $sm-max-width) {
      font-size: rem(33);
    }
  }

  &-form-slogan-note {
    text-align: center;
    margin-bottom: rem(45);
    color: $purple-gray;
    font-size: rem(18);
    line-height: rem(30);

    b {
      color: $darken-purple;
      font-weight: 700;
    }

    a {
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  &-form-item {
    margin-bottom: rem(25);

    &-checkbox {
      margin-bottom: 0;
    }
  }

  &-form-label {
    color: $black;
    text-transform: uppercase;
    font-weight: 400;
    font-size: rem(12);
    cursor: pointer;
    margin-bottom: rem(18);

    &-checkbox {
      margin-bottom: rem(18);
      position: relative;
      padding-left: rem(40);

      &::before {
        content: '';
        position: absolute;
        top: rem(-6);
        left: 0;
        width: 25px;
        height: 25px;
        background-color: $white;
        border-radius: 5px;
      }
    }
  }

  &-file-input-wrapper {
    border-radius: 5px;
    background-color: $white;
  }

  &-input {
    display: block;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: $black;
    font-size: rem(16);
    padding-top: rem(6);
    padding-bottom: rem(7);
    padding-left: rem(15);
    padding-right: rem(15);

    &-controled {
      padding-right: rem(100);
    }

    &-checkbox {
      &:checked {
        ~ .ctf-hire-form-label-checkbox {
          &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 6px;
            z-index: 1;
            width: 14px;
            border: 2px solid;
            transform: rotate(-45deg);
            height: 7px;
            border-color: #009688;
            border-top-style: none;
            border-right-style: none;
          }
        }
      }

      &:focus {
        ~ .ctf-hire-form-label-checkbox {
          &::before {
            outline: 1px solid #9bc0f7;
          }
        }
      }
    }

    &-file {
      font-weight: 400;
      font-size: rem(13);
    }

    &-bold {
      font-weight: 900;
    }
  }

  &-textarea {
    height: rem(150);
    display: block;
    width: 100%;
    border-radius: 5px;
    border: none;
    resize: none;
    padding-top: rem(6);
    padding-bottom: rem(7);
    padding-left: rem(15);
    padding-right: rem(15);
  }

  &-subm-button {
    display: block;
    width: 100%;
    text-align: center;
    color: $white;
    font-size: rem(14);
    line-height: rem(24);
    font-weight: 700;
    background-color: $green;
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
    padding-top: rem(12);
    padding-bottom: rem(10);
    margin-top: rem(15);
    margin-bottom: rem(27);
  }

  &-form-note {
    font-size: rem(12);
    line-height: rem(18);
    font-weight: 400;
  }
}
